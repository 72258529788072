<template>
  <b-card no-body class="card-statistics">
    <b-card-header>
      <b-card-title class="pb-1">{{$t('instagram.priceDollars')}}</b-card-title>
      <b-card-sub-title class="text-secondary mb-20 font">{{$t('instagram.averageData')}}</b-card-sub-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <div class="mt-1" >
        <h3 class="custom-p">
          {{ $t('profile.estimate_price') }}
          <b-icon
            font-scale="1"
            icon="question-circle"
            class="icon-tooltip"
            id="priceEstimated"
          ></b-icon>
            <b-tooltip target="priceEstimated" triggers="hover" variant="primary">
              {{$t('instagram.tooltipPriceDollars')}}
            </b-tooltip>
        </h3>
        <b-row>
          <b-col cols="6" class="text-start">
            <h4 v-if="post_price_from > 0"><strong>${{ numberFormat(post_price_from) }} - ${{ numberFormat(post_price_to) }}</strong></h4>
            <h4 v-else><strong>{{ $t('notAvailable') }}</strong></h4>
            <p class="notas">{{$t('profile.Publication')}}</p>
          </b-col>

          <b-col cols="6" class="text-start">
            <h4 v-if="stories_price_from > 0"><strong>${{ numberFormat(stories_price_from) }} - ${{ numberFormat(stories_price_to) }}</strong></h4>
            <h4 v-else><strong>{{ $t('notAvailable') }}</strong></h4>
            <p class="notas">Story</p>
          </b-col>
        </b-row>
        <hr/>
        <div>
          <h3 class="custom-p">
            CPE
            <b-icon
            font-scale="1"
            icon="question-circle"
            class="icon-tooltip"
            id="cpeTooltip"
          ></b-icon>
            <b-tooltip target="cpeTooltip" triggers="hover" variant="primary">
              {{$t('instagram.tooltipCpe')}}
            </b-tooltip>
          </h3>
      
          <b-col cols="6" class="container-percentage">
            <h4 v-if="cpe_from > 0" class="d-flex align-items-start">
              <div :class="`${classBadge(cpe_mark)} circle-badge`"></div>
              <strong>${{ numberFormat(cpe_from) }} - ${{ numberFormat(cpe_to) }}</strong>
            </h4>
            <span>{{textNivel(cpe_mark)}}</span>
          </b-col>
        </div>
        <hr/>
          <div>
          <h3 class="custom-p">
            EMV
            <b-icon
            font-scale="1"
            icon="question-circle"
            class="icon-tooltip"
            id="emvTooltip"
          ></b-icon>
            <b-tooltip target="emvTooltip" triggers="hover" variant="primary">
              {{$t('instagram.tooltipEmv')}}
            </b-tooltip>
          </h3>
      
          <b-col cols="6" class="container-percentage">
            <h4 v-if="emv_from > 0" class="d-flex align-items-start">
              <div :class="`${classBadge(emv_mark)} circle-badge`"></div>
              <strong>${{ numberFormat(emv_from) }} - ${{ numberFormat(emv_to) }}</strong>
            </h4>
            <span>{{textNivel(emv_mark)}}</span>
          </b-col>
        </div>
        <hr/>
      </div>

    </b-card-body>
  </b-card>
</template>
<script>
import { BRow, BCol, BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, BTooltip } from 'bootstrap-vue'
import { numberFormat} from '@/libs/utils/formats';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTooltip,
  },
  data() {
    return {
      numberFormat,
      post_price_from: this.data?.blogger_prices?.post_price_from ?? 0,
      post_price_to: this.data?.blogger_prices?.post_price_to ?? 0,
      stories_price_from: this.data?.blogger_prices?.stories_price_from ?? 0,
      stories_price_to: this.data?.blogger_prices?.stories_price_to ?? 0,
      emv_from: this.data?.blogger_emv?.emv_from ?? 0,
      emv_to: this.data?.blogger_emv?.emv_to ?? 0,
      cpe_from: this.data?.blogger_prices?.cpe_from ?? 0,
      cpe_to: this.data?.blogger_prices?.cpe_to ?? 0,
      emv_mark: this.data?.blogger_emv?.emv_mark ?? '',
      cpe_mark: this.data?.blogger_prices?.cpe_mark ?? '',
    }
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  methods: {
    textNivel(text) {
      if (text === "good") return this.$t('profile.goodNivel')
      if (text === "very_good") return this.$t('profile.mediumNivel')
      return 'N/A'
    },
    classBadge(text) {
      if (text === "good") return 'aprovado'
      if (text === "very_good") return 'promedio'
      return 'N/A'
    }
  }
}
</script>
<style scoped>
.circle-badge{
  width:15px;
  height:15px;
  border-radius:50%;
  margin-right: 5px;
}
.aprovado{
  background: green;
}
.promedio{
    background: orange;
}
.peligro {
  background: red;
}
</style>